@import "../../colors.scss";

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  height: 500px;

  gap: 10px;

  a:hover {
    .image {
      outline: 3px dashed $colorMain ;
    }
  }

  .image {
    width: 400px;
    height: 400px;
    outline: 3px solid $colorMain;
    border-radius: 25px;
    overflow: hidden;
    position: relative;

    .gradient {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(rgba(24, 24, 24, 0) 70%, $colorMain);
      z-index: 1;
    }

    .name {
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      font-size: 26px;
      font-weight: 700;
      width: 100%;
      margin: 10px 0;
      text-align: center;
      text-decoration: none;
      color: $colorText;
    }

    img {
      height: 100%;
      min-width: 100%;
    }
  }

  .project-tools {
    width: 90%;
    font-size: 18px;
    text-align: center;
    color: $colorText60;
  }
}

@media only screen and (max-width: 500px) {
  .project {
    .image {
      width: 80vw;
      height: 80vw;
    }

    .project-tools {
      width: 90vw;
    }
  }
}