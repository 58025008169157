@import "colors";

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $colorText;
  background-color: $colorBg;
}

button {
  background-color: $colorMain;
  border: none;
  border-radius: 10px;

  color: $colorText;
  font-size: 24px;
  font-weight: 400;
  padding: 12px 28px;

  cursor: pointer;

  &:hover {
    background-color: $colorBg;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23EC1E28' stroke-width='6' stroke-dasharray='14%2c 14' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    color: $colorMain;
  }
}