@import "../../colors.scss";

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;

  margin-top: 180px;

  .copy {
    font-size: 18px;
    color: $colorMain;
  }
}