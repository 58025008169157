@import "../../colors";

.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 580px;

  p {
    margin: 0;
  }

  .hero-content {
    width: max-content;

    .text {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 30px;

      .greeting {
        font-size: 56px;

        .bold {
          font-weight: 700;
        }

        .super {
          color: $colorMain;
        }
      }

      .title {
        display: flex;
        align-items: center;
        gap: 20px;

        font-size: 24px;
        font-weight: 600;
        text-wrap: nowrap;

        &::before {
          content: "";
          display: inline;
          width: 100%;
          height: 3px;
          background-color: $colorMain;
        }
      }

      .slogan {
        font-size: 18px;
        color: $colorText60;
      }
    }
  }

  .hero-image {
    display: flex;
    justify-content: center;
    width: 572px;
    position: relative;

    .image-container {
      position: relative;
      width: 100%;
      height: 100%;

      .gradient {
        bottom: 0;
        position: absolute;
        max-height: 302px;
        width: 100%;
        height: 100%;
        z-index: 2;
        border-radius: 25px;
        background-image: linear-gradient(rgba(24, 24, 24, 0) 60%, $colorMain);
      }

      img {
        position: relative;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .image-border {
        width: 100%;
        height: 80%;
        outline: 3px solid $colorMain;
        border-radius: 25px;
        bottom: 0;
        left: 0;
        position: absolute;
      }
    }

  }
}

@media only screen and (max-width: 1240px) {
  .hero {
    .title {
      text-wrap: auto !important;
    }
  }
}

@media only screen and (max-width: 800px) {
  .hero {
    flex-direction: column;
    justify-content: center;
    gap: 60px;

    height: max-content;

    .hero-content {
      .text {
        .greeting {
          font-size: 8vw;
        }
      }
    }

    .hero-image {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 470px) {
  .hero {
    .hero-image {
      .image-container {
        img {
          width: 90%;
        }
      }
    }
  }
}