@import "../../colors.scss";

.aboutme {
  display: flex;
  justify-content: space-between;
  min-height: 800px;
  height: max-content;

  .aboutme-image {
    position: relative;
    width: max-content;
    height: max-content;

    .gradient {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(24, 24, 24, 0) 40%, #181818);
    }

    .name {
      position: absolute;
      font-size: 48px;
      font-weight: 700;
      text-align: right;
      line-height: 100%;
      color: $colorMain;
      right: -55px;
      bottom: -10px;
      z-index: 1;
    }
  }

  .content {
    max-width: 678px;
    margin-left: 50px;

    .desc {
      margin-bottom: 50px;

      .headline {
        margin-top: 0;
        margin-bottom: 20px;
        font-size: 48px;
        color: $colorMain;
      }

      .desc-text {
        margin: 0;
        font-size: 24px;
        max-width: 100%;
      }
    }

    .tools-section {
      .headline {
        margin-top: 0;
        margin-bottom: 25px;
        color: $colorMain;
        font-size: 32px;
      }

      .tools-container {
        display: flex;
        flex-wrap: wrap;
        gap: 50px;
      }
    }
  }
}

@media only screen and (max-width:900px) {
  .aboutme {
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }
}

@media only screen and (max-width:600px) {
  .aboutme {
    .aboutme-image {
      .name {
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
      }

      img {
        width: 70vw;
      }
    }

    .content {
      margin-left: 0;

      .tools-container {
        .tools {
          .tools-headline {
            font-size: 20px;
          }

          .item {
            font-size: 20px;
          }
        }
      }
    }
  }
}