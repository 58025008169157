@import "../../colors.scss";

.tools {
  .tools-headline {
    color: $colorMain;
    font-size: 26px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 8px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 15px;

    list-style: none;
    margin: 0;
    padding: 0;
    gap: 10px;

    .item {
      color: $colorText60;
      font-size: 24px;
    }
  }
}