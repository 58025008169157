@import "../../colors";

.navbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  z-index: 999;
  top: 0;
  height: 80px;
  background-color: $colorBg;

  .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 800;
    font-size: 48px;
    color: $colorMain;
  }

  .redirections {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px;

    a {
      display: flex;
      align-self: flex-end;

      .icon {
        width: 40px;
        height: 40px;
      }

      &:hover {
        .icon {
          filter: brightness(10);
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .navbar {
    justify-content: space-between;

    .logo {
      position: unset;
      left: unset;
      transform: unset;

      margin-left: 20px;
    }
  }
}