@import "../../colors";

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;

  .headline {
    text-align: center;
    color: $colorMain;
    font-size: 48px;
    margin-top: 0;
    margin-bottom: 40px;
  }

  .contact-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    list-style: none;

    margin: 0;
    padding: 0;

    a {
      text-decoration: none;

      &:hover {
        p {
          color: #FFFFFF !important;
        }
      }
    }

    .contact-item {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 24px;
      color: $colorMain;

      p {
        margin: 0;
      }

      img {
        width: 45px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .contact {
    .contact-list {
      .contact-item {
        font-size: 18px;
      }
    }
  }
}